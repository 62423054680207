<template>
  <div>
    <!-- pic6065D8DD447D4E20B91FC334DE3652A2.jpg -->
    <!-- download645EC7EF1FF4BA28A78749550BC2D781.flv -->
    <video class="rounded"
           src="@/assets/news-network/videos/lu-logo-coming-to-life.mp4"
           poster="@/assets/news-network/lu-logo-coming-to-life-1.jpg"
           onclick="this.paused ? this.play() : this.pause();">
    </video>
    <p>
      Built to reflect an evolving LEGO Universe, the game’s logo was designed to come alive! But how can you harness the sheer storytelling power of this animated symbol?
    </p>
    <p>
      The LEGO Universe team meets the challenge by creating static and dynamic storyboards like these. Storyboards are more like sketches than finished paintings, so the final logo animations will look dramatically different!
    </p>
    <p>
      View the animated sketch then look closely at the static version below. See how the still storyboard shows the moments when the world changes most? Just five frames to thread together the entire tale!
    </p>
    <div style="clear:both;"></div>

    <!-- pic23B600FFC1666F6430FA8592BF3E3E69.jpg -->
    <img class="rounded" style="height: 251px;" src="@/assets/news-network/lu-logo-coming-to-life-2.jpg">
    <p><i>Frame 1</i></p>

    <!-- pic94E31411C8FB325C8B4EBE347233EF40.jpg -->
    <img class="rounded" style="height: 251px;" src="@/assets/news-network/lu-logo-coming-to-life-2.jpg">
    <p><i>Frame 2</i></p>

    <!-- picE6C21679B08DA6E1EC62D0190B5397E2.jpg -->
    <img class="rounded" style="height: 251px;" src="@/assets/news-network/lu-logo-coming-to-life-3.jpg">
    <p><i>Frame 3</i></p>

    <!-- pic7E093B90703C28FA2DB974EDAF4A190F.jpg -->
    <img class="rounded" style="height: 251px;" src="@/assets/news-network/lu-logo-coming-to-life-4.jpg">
    <p><i>Frame 4</i></p>

    <!-- pic91B4A3F0CD84E1F010E26DDF10350CD3.jpg -->
    <img class="rounded" style="height: 251px;" src="@/assets/news-network/lu-logo-coming-to-life-5.jpg">
    <p><i>Frame 5</i></p>

    <p>
      Now it's your turn so show off your skills. Join the new
      <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '63CFA64A-2921-409C-BB26-CC2F02D7A6C7'} }">Layout a living logo tale</router-link>
      challenge!
    </p>
    <p>
      Stay tuned to the LEGO Universe News Network to see fully realized animations featuring the logo in 3-D living color! Subscribers to the <b>free</b> LEGO Universe newsletter get exclusive details first, so
      <router-link :to="{ name: 'newsletter', params: {} }">sign up</router-link>
      if you haven’t already!
    </p>
  </div>
</template>
